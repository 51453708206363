import consumer from "./consumer"

consumer.subscriptions.create("AlertChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var alert = '<div class="alert alert-' + data.type + '"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + data.html + '</div>';
    $('#render-alert').html(alert)
  }
});
