import consumer from './consumer';
import $ from 'jquery';

$(document).ready(function() {
  consumer.subscriptions.create({
    channel: 'IndexProgramChannel'
  }, {
    connected() {
      console.log('message connected');
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      console.log(data);
      $('#btn-export').html('<div class=\'btn btn-success disabled\'>Export to Kibana</div>');
      $('#export-message .col-md-12').html(data.html);
    },
  });
});
